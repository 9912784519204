import { MapDirectionsService, MapGeocoder } from "@angular/google-maps";
import { UserDto, WageListDto } from "app/api/models";
import { sumList } from "app/app.component";
import { TimeUtil } from "app/core/utils/time-utils";

export class JobUtils {

    public static generateWage(startDate: Date, endDate: Date, driver: UserDto, ref: string) {
        // Rate A - Mon - fri (6am - 6pm)
        // Rate B - Other hours
        console.log("start date, end date", startDate, endDate);

        var totalHours = TimeUtil.getHoursBetween(startDate, endDate);

        console.log("total hours", totalHours);

        var hoursAStart = TimeUtil.setTo1amUTC(new Date(startDate));
        hoursAStart.setHours(6);
        var hoursAEnd = TimeUtil.setTo1amUTC(new Date(startDate));
        hoursAEnd.setHours(18);
        var hoursA = TimeUtil.getHoursOfOverlap(startDate, endDate, hoursAStart, hoursAEnd);
        var hoursB = totalHours - hoursA;

        console.log("hoursAstart, hoursAend", hoursAStart, hoursAEnd);

        var pay = hoursA * driver?.hourlyRateA + hoursB * driver?.hourlyRateB;
        var additions = 0;

        var wage = <WageListDto> {
            dateCreated: new Date().toISOString(),
            driverId: driver.id,
            hoursA: hoursA,
            hoursB: hoursB,
            reference: ref, //'auto-complete-job', //get new number
            fares: pay,
            additions: additions,
            total: pay + additions,
            // status?: WageStatusDto;
        }

        return wage;
    }

    public static getJobDialogData(isLastJob: boolean) {
        var items: any[] = [
            {name: 'hours', mandatory: true, type: 'number'},
        ]

        if(isLastJob)
            items.push({name: 'Return Location', mandatory: true, propertyName: 'location', locationButton: true });

        return {
            panelClass: 'max-width-item-dialog',
            data: {
                title: "Finish Job",
                items: items,
            }
        };
    }

    public static openLocation(location: string) {
        if(!location) return;

        window.open("http://maps.apple.com/maps?q=" + location); //todo sanitize it??
    }

    
    public static async getGoogleRoute(loc1: string, loc2: string, geocoder: MapGeocoder, directions: MapDirectionsService): Promise<google.maps.DirectionsRoute[]> {
        if(!loc1 || !loc2) return null;

        var res1 = await geocoder.geocode({address: loc1}).toPromise();
        if(!res1.status || !res1.results?.length) return;

        var coords1 = res1.results[0].geometry.location;

        var res2 = await geocoder.geocode({address: loc2}).toPromise();
        if(!res2.status || !res2.results?.length) return;

        var coords2 = res2.results[0].geometry.location;

        var req: google.maps.DirectionsRequest = {
            travelMode: google.maps.TravelMode.DRIVING,
            origin: {
            lat: coords1.lat(),
            lng: coords1.lng(),
            },
            destination: {
            lat: coords2.lat(),
            lng: coords2.lng(),
            },
            provideRouteAlternatives: false,
        };

        console.log("route req", req);

        var res3 = await directions.route(req).toPromise();

        if(!res3.status || !res3.result) return;

        return res3.result.routes;
    }

    public static async calculateDuration(loc1: string, loc2: string, geocoder: MapGeocoder, directions: MapDirectionsService) {
        var routes = await JobUtils.getGoogleRoute(loc1, loc2, geocoder, directions);
        
        var duration = sumList(routes[0].legs.map(x => x.duration.value / 3600)); //seconds to hours

        return duration;
    }

    public static async calculateMileage(loc1: string, loc2: string, geocoder: MapGeocoder, directions: MapDirectionsService) {
        var routes = await JobUtils.getGoogleRoute(loc1, loc2, geocoder, directions);
        
        var distance = sumList(routes[0].legs.map(x => x.distance.value * 0.000621371)); //meters to miles

        return distance;
    }

}
