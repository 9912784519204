/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { HazardControlDto } from '../models/hazard-control-dto';
import { HazardControlDtoBaseResponse } from '../models/hazard-control-dto-base-response';
import { OrderDirection } from '../models/order-direction';
import { PowraFormDto } from '../models/powra-form-dto';
import { PowraFormDtoBaseResponse } from '../models/powra-form-dto-base-response';
import { PowraFormDtoPagedResponseBaseResponse } from '../models/powra-form-dto-paged-response-base-response';
import { PropertyFilter } from '../models/property-filter';

@Injectable({
  providedIn: 'root',
})
export class FormService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiFormGetPowraFormForDateAndUserGet
   */
  static readonly ApiFormGetPowraFormForDateAndUserGetPath = '/api/Form/get-powra-form-for-date-and-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormGetPowraFormForDateAndUserGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetPowraFormForDateAndUserGet$Plain$Response(params?: {
    date?: string;
    userId?: number;
  }): Observable<StrictHttpResponse<PowraFormDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormGetPowraFormForDateAndUserGetPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PowraFormDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormGetPowraFormForDateAndUserGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetPowraFormForDateAndUserGet$Plain(params?: {
    date?: string;
    userId?: number;
  }): Observable<PowraFormDtoBaseResponse> {

    return this.apiFormGetPowraFormForDateAndUserGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PowraFormDtoBaseResponse>) => r.body as PowraFormDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormGetPowraFormForDateAndUserGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetPowraFormForDateAndUserGet$Json$Response(params?: {
    date?: string;
    userId?: number;
  }): Observable<StrictHttpResponse<PowraFormDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormGetPowraFormForDateAndUserGetPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PowraFormDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormGetPowraFormForDateAndUserGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetPowraFormForDateAndUserGet$Json(params?: {
    date?: string;
    userId?: number;
  }): Observable<PowraFormDtoBaseResponse> {

    return this.apiFormGetPowraFormForDateAndUserGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PowraFormDtoBaseResponse>) => r.body as PowraFormDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiFormGetPowraFormByIdGet
   */
  static readonly ApiFormGetPowraFormByIdGetPath = '/api/Form/get-powra-form-by-id';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormGetPowraFormByIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetPowraFormByIdGet$Plain$Response(params?: {
    powraFormId?: number;
  }): Observable<StrictHttpResponse<PowraFormDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormGetPowraFormByIdGetPath, 'get');
    if (params) {
      rb.query('powraFormId', params.powraFormId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PowraFormDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormGetPowraFormByIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetPowraFormByIdGet$Plain(params?: {
    powraFormId?: number;
  }): Observable<PowraFormDtoBaseResponse> {

    return this.apiFormGetPowraFormByIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PowraFormDtoBaseResponse>) => r.body as PowraFormDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormGetPowraFormByIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetPowraFormByIdGet$Json$Response(params?: {
    powraFormId?: number;
  }): Observable<StrictHttpResponse<PowraFormDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormGetPowraFormByIdGetPath, 'get');
    if (params) {
      rb.query('powraFormId', params.powraFormId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PowraFormDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormGetPowraFormByIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetPowraFormByIdGet$Json(params?: {
    powraFormId?: number;
  }): Observable<PowraFormDtoBaseResponse> {

    return this.apiFormGetPowraFormByIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PowraFormDtoBaseResponse>) => r.body as PowraFormDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiFormGetPowraFormsGet
   */
  static readonly ApiFormGetPowraFormsGetPath = '/api/Form/get-powra-forms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormGetPowraFormsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetPowraFormsGet$Plain$Response(params?: {
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<PowraFormDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormGetPowraFormsGetPath, 'get');
    if (params) {
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PowraFormDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormGetPowraFormsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetPowraFormsGet$Plain(params?: {
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<PowraFormDtoPagedResponseBaseResponse> {

    return this.apiFormGetPowraFormsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PowraFormDtoPagedResponseBaseResponse>) => r.body as PowraFormDtoPagedResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormGetPowraFormsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetPowraFormsGet$Json$Response(params?: {
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<PowraFormDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormGetPowraFormsGetPath, 'get');
    if (params) {
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PowraFormDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormGetPowraFormsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormGetPowraFormsGet$Json(params?: {
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<PowraFormDtoPagedResponseBaseResponse> {

    return this.apiFormGetPowraFormsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PowraFormDtoPagedResponseBaseResponse>) => r.body as PowraFormDtoPagedResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiFormAddPowraFormPost
   */
  static readonly ApiFormAddPowraFormPostPath = '/api/Form/add-powra-form';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormAddPowraFormPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormAddPowraFormPost$Plain$Response(params?: {
    body?: PowraFormDto
  }): Observable<StrictHttpResponse<PowraFormDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormAddPowraFormPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PowraFormDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormAddPowraFormPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormAddPowraFormPost$Plain(params?: {
    body?: PowraFormDto
  }): Observable<PowraFormDtoBaseResponse> {

    return this.apiFormAddPowraFormPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PowraFormDtoBaseResponse>) => r.body as PowraFormDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormAddPowraFormPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormAddPowraFormPost$Json$Response(params?: {
    body?: PowraFormDto
  }): Observable<StrictHttpResponse<PowraFormDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormAddPowraFormPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PowraFormDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormAddPowraFormPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormAddPowraFormPost$Json(params?: {
    body?: PowraFormDto
  }): Observable<PowraFormDtoBaseResponse> {

    return this.apiFormAddPowraFormPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PowraFormDtoBaseResponse>) => r.body as PowraFormDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiFormUpdatePowraFormPut
   */
  static readonly ApiFormUpdatePowraFormPutPath = '/api/Form/update-powra-form';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormUpdatePowraFormPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormUpdatePowraFormPut$Plain$Response(params?: {
    body?: PowraFormDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormUpdatePowraFormPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormUpdatePowraFormPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormUpdatePowraFormPut$Plain(params?: {
    body?: PowraFormDto
  }): Observable<BooleanBaseResponse> {

    return this.apiFormUpdatePowraFormPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormUpdatePowraFormPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormUpdatePowraFormPut$Json$Response(params?: {
    body?: PowraFormDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormUpdatePowraFormPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormUpdatePowraFormPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormUpdatePowraFormPut$Json(params?: {
    body?: PowraFormDto
  }): Observable<BooleanBaseResponse> {

    return this.apiFormUpdatePowraFormPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiFormToggleArchiveFormPut
   */
  static readonly ApiFormToggleArchiveFormPutPath = '/api/Form/toggle-archive-form';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormToggleArchiveFormPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormToggleArchiveFormPut$Plain$Response(params?: {
    powraFormId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormToggleArchiveFormPutPath, 'put');
    if (params) {
      rb.query('powraFormId', params.powraFormId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormToggleArchiveFormPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormToggleArchiveFormPut$Plain(params?: {
    powraFormId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiFormToggleArchiveFormPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormToggleArchiveFormPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormToggleArchiveFormPut$Json$Response(params?: {
    powraFormId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormToggleArchiveFormPutPath, 'put');
    if (params) {
      rb.query('powraFormId', params.powraFormId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormToggleArchiveFormPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormToggleArchiveFormPut$Json(params?: {
    powraFormId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiFormToggleArchiveFormPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiFormAddHazardControlPost
   */
  static readonly ApiFormAddHazardControlPostPath = '/api/Form/add-hazard-control';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormAddHazardControlPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormAddHazardControlPost$Plain$Response(params?: {
    body?: HazardControlDto
  }): Observable<StrictHttpResponse<HazardControlDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormAddHazardControlPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HazardControlDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormAddHazardControlPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormAddHazardControlPost$Plain(params?: {
    body?: HazardControlDto
  }): Observable<HazardControlDtoBaseResponse> {

    return this.apiFormAddHazardControlPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<HazardControlDtoBaseResponse>) => r.body as HazardControlDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormAddHazardControlPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormAddHazardControlPost$Json$Response(params?: {
    body?: HazardControlDto
  }): Observable<StrictHttpResponse<HazardControlDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormAddHazardControlPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HazardControlDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormAddHazardControlPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormAddHazardControlPost$Json(params?: {
    body?: HazardControlDto
  }): Observable<HazardControlDtoBaseResponse> {

    return this.apiFormAddHazardControlPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<HazardControlDtoBaseResponse>) => r.body as HazardControlDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiFormUpdateHazardControlPut
   */
  static readonly ApiFormUpdateHazardControlPutPath = '/api/Form/update-hazard-control';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormUpdateHazardControlPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormUpdateHazardControlPut$Plain$Response(params?: {
    body?: HazardControlDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormUpdateHazardControlPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormUpdateHazardControlPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormUpdateHazardControlPut$Plain(params?: {
    body?: HazardControlDto
  }): Observable<BooleanBaseResponse> {

    return this.apiFormUpdateHazardControlPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormUpdateHazardControlPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormUpdateHazardControlPut$Json$Response(params?: {
    body?: HazardControlDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormUpdateHazardControlPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormUpdateHazardControlPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiFormUpdateHazardControlPut$Json(params?: {
    body?: HazardControlDto
  }): Observable<BooleanBaseResponse> {

    return this.apiFormUpdateHazardControlPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiFormArchiveHazardControlPut
   */
  static readonly ApiFormArchiveHazardControlPutPath = '/api/Form/archive-hazard-control';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormArchiveHazardControlPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormArchiveHazardControlPut$Plain$Response(params?: {
    hazardControlId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormArchiveHazardControlPutPath, 'put');
    if (params) {
      rb.query('hazardControlId', params.hazardControlId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormArchiveHazardControlPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormArchiveHazardControlPut$Plain(params?: {
    hazardControlId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiFormArchiveHazardControlPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFormArchiveHazardControlPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormArchiveHazardControlPut$Json$Response(params?: {
    hazardControlId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, FormService.ApiFormArchiveHazardControlPutPath, 'put');
    if (params) {
      rb.query('hazardControlId', params.hazardControlId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiFormArchiveHazardControlPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFormArchiveHazardControlPut$Json(params?: {
    hazardControlId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiFormArchiveHazardControlPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
