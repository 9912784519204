import { KeyValue } from "@angular/common";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FuseConfirmationService } from "@fuse/services/confirmation";
import { AccountService, UserImageService } from "app/api/services";
import { JwtAuthService } from "app/api/services/cookie-jwt-auth/jwt-auth.service";
import { User } from "app/core/models/user.model";
import { ProjectHelper } from "app/views/admin/pages/projects/project/project.helper";
import { forEach } from "lodash";

export class GenericCommentDto {
  public id?: number;
  public parentId?: number;
  public text?: string
  public userId?: number;
  public timeStamp?: Date;
}

@Component({
  selector: "app-comments",
  templateUrl: "./comments.component.html",
  styleUrls: ["./comments.component.scss"]
})
export class CommentsComponent extends ProjectHelper implements OnInit {
  @Input() comments: GenericCommentDto[] = [];
  
  @Input() parentId: number;
  @Input() titleClass: string = ''; // empty - use mat-label
  @Output() $commentAdded = new EventEmitter<GenericCommentDto>();
  @Output() $commentChanged = new EventEmitter<GenericCommentDto>();
  @Output() $commentDeleted = new EventEmitter<number>();

  public currentText: string = '';
  public currentId: number = null;
  public currentUser: User;
  public editingComment: boolean = false;
  
  avatarDictionary: KeyValue<number, string>[] = [];

  constructor(
    public accountService: AccountService,
    public userImageService: UserImageService,
    public cdr: ChangeDetectorRef,
    public jwtAuth: JwtAuthService,
    public _fuseConfirmationService: FuseConfirmationService,
  ) {
    super();
  }

  ngOnInit(): void {
    console.log("comments init");
    this.loadUsers();

    this.currentUser = this.jwtAuth.getUser();
  }

  loadUsers() {
    console.log("loading users - comments");
    this.accountService.apiAccountSearchAllGet$Json$Response({ archived: false }).subscribe(res => {
      console.log("loaded users - comments");
      if(!res?.body.data) return;

      this.allUsers = res.body.data;

      this.loadAvatars();

      this.cdr.detectChanges();
    });
  }

  openCommentEdit(comment: GenericCommentDto) {
    this.currentId = comment.id;
    this.currentText = comment.text;
    this.editingComment = true;
    this.cdr.detectChanges();
  }

  saveButtonClicked() {
    this.editingComment ? this.updateComment() : this.addComment();
  }

  addComment() {
    if(this.currentText.trim() === '') return;

    var newComment = <GenericCommentDto> {
      parentId: this.parentId,
      userId: Number(this.currentUser.id),
      text: this.currentText,
      timeStamp: new Date(),
    };

    this.commentAdded(newComment);
    this.wipeComment();
  }

  updateComment() {
    if(this.currentText.trim() === '') return;
    
    var comment = <GenericCommentDto> {
      id: this.currentId,
      parentId: this.parentId,
      userId: Number(this.currentUser.id),
      text: this.currentText,
      timeStamp: new Date(),
    };

    this.commentChanged(comment);
    this.wipeComment();
  }

  deleteComment(id: number) {
    const confirmation = this._fuseConfirmationService.open({
      title  : 'Delete Comment',
      message: `Are you sure you want to remove this comment?`,
      actions: {
          confirm: {
              label: 'Delete',
          },
      },
    });

    confirmation.afterClosed().subscribe((result) => {
      if (result === 'confirmed')
        this.commentDeleted(id);
    });
  }

  wipeComment() {
    this.currentId = null;
    this.currentText = '';
    this.editingComment = false;
    this.cdr.detectChanges();
  }

  canEditComment(comment: GenericCommentDto) {
    return comment?.userId == Number(this.currentUser?.id);
    //perhaps allow admins/superadmins to always edit/delete comments, or some other rules here
  }

  private commentAdded = (comment: GenericCommentDto): void => this.$commentAdded.emit(comment);
  private commentChanged = (comment: GenericCommentDto): void => this.$commentChanged.emit(comment);
  private commentDeleted =(id: number): void => this.$commentDeleted.emit(id);


  loadAvatars(){
      var userIds = this.allUsers.map(x => x.id);
      console.log("loading avatars - comments");
      this.userImageService.apiUserImageAllUserPhotosPost$Json$Response({body: userIds}).subscribe(res => {
          console.log("loaded avatars - comments");
          if(!res?.body?.data) return;

          var imageInfo = res?.body.data;
          
          this.avatarDictionary = [];        
          userIds.forEach(id => {
              if (!this.avatarDictionary[id]) {
                  this.avatarDictionary[id] = null;
              }
          });

          forEach(imageInfo, (value, key) => {
              this.avatarDictionary[key] = `data:${value?.contentType};base64,${value?.data}`;
          });
      });
  }

  getAvatar(userId: number) {
      if(!userId) return null;

      return this.avatarDictionary[userId];
  }

}
