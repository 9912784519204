import { formatNumber } from "@angular/common";

export class TimeUtil {

    public static getHoursText(hours: number) {
        if(!hours) return '0h'; //None';

        var hr = Math.trunc(hours);
        var mn = Math.round((hours % 1) * 60);

        return (hr > 0 ? `${hr}h ` : '') + (mn > 0 ? `${mn}m` : '');
    }

    public static getHoursTextMinimal(hours: number) {
        if(!hours) return '0h';

        var hr = Math.trunc(hours);
        var mn = Math.round((hours % 1) * 60);

        return (hr > 0 ? `${formatNumber(hours, "en-US", "1.0-2")}h ` : `${mn}m`);
    }

    public static getHoursFromHoursText(text: string) {
        if(!text.includes('h') && !text.includes('m')) {
            const dregex = /(\d+(.\d+)?)/g;
            const dfound = text.match(dregex);
            return dfound?.length ? dfound[0] : '0';
        }

        const hregex = /(\d+(.\d+)?)h/g;
        const hfound = text.match(hregex);
        var hours = hfound?.length ? hfound[0] : '0';

        const mregex = /(\d+)m/g;
        const mfound = text.match(mregex);
        var mins = mfound?.length ? mfound[0] : '0';

        if(!hfound && !mfound) return '0';

        return (Number.parseFloat(hours) + Number.parseInt(mins) / 60).toFixed(2);
    }

    public static getDaysBetween(date1: Date, date2: Date) {
        return Math.floor((Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) - Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate()) ) /(1000 * 60 * 60 * 24));
    }

    public static getHoursBetween(date1: Date, date2: Date) {
        return Math.abs(date1.getTime() - date2.getTime()) / 3600000;
    }

    public static addHours(date: Date, hours: number): Date {
        date.setTime(date.getTime() + (hours*60*60*1000));
        return date;
    }

    public static setTo1amUTC(date: Date) {
        if(date.getHours() == 0)
            TimeUtil.addHours(date, 1);
        date.setHours(2);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    }

    public static setTo2359UTC(date: Date) {
        TimeUtil.setTo1amUTC(date);
        date.setHours(23, 59);
        return date;
    }

    public static setTo1stOfMonth(date: Date) {
        TimeUtil.setTo1amUTC(date);
        date.setDate(1);
        return date;
    }

    public static setToEndOfMonth(date: Date) {
        TimeUtil.setTo1stOfMonth(date);
        TimeUtil.addMonths(date, 1);
        TimeUtil.addDays(date, -1);
        TimeUtil.setTo2359UTC(date);
        return date;
    }

    public static setToStartOfWeek(date: Date) {
        TimeUtil.setTo1amUTC(date);
        TimeUtil.addDays(date, 1 + date.getDay() * -1);
        return date;
    }

    public static setToEndOfWeek(date: Date) {
        TimeUtil.setToStartOfWeek(date);
        TimeUtil.addDays(date, 6);
        TimeUtil.setTo2359UTC(date);
        return date;
    }
    
    public static addDays(date: Date, days: number) {
        date.setTime(date.getTime() + (days*24*60*60*1000));
        return date;
    }

    public static addMonths(date: Date, months: number) {
        var previousMonth = date.getMonth();
        var newMonth = previousMonth + months;
        var newYear = newMonth >= 12 ? date.getFullYear() + 1 : date.getFullYear();

        var newDate = new Date(newYear, newMonth % 12, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
        date.setTime(newDate.getTime());
        return date;
    }
    
    public static addYears(date: Date, years: number) {
        var newDate = new Date(date.getFullYear() + years, date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
        date.setTime(newDate.getTime());
        return date;
    }

    public static collateDateAndTimeStrings(date: string, time: string) {
        return TimeUtil.addHours(new Date(date), +time.split(':')[0] + (+time.split(':')[1] / 60))
    }

    public static goesOverAnyWeekends (from: Date, to: Date): boolean {
        while (from < to) {
          var day = from.getDay();
          if (day === 6 || day === 0) return true;
          TimeUtil.addDays(from, 1);
        }
        return false;
    }

    public static dateRangesOverlap(start1: Date, end1: Date, start2: Date, end2: Date) {
        return start1 <= end2 && start2 <= end1;
    }

    public static getHoursOfOverlap(start1: Date, end1: Date, start2: Date, end2: Date) {
        if(!TimeUtil.dateRangesOverlap(start1, end1, start2, end2)) return 0;

        if(start2 > start1 && end2 > end1)
            return TimeUtil.getHoursBetween(start2, end1);

        if(start2 < start1 && end2 < end1)
            return TimeUtil.getHoursBetween(end2, start1);

        if(start1 < start2 && end2 < end1)
            return TimeUtil.getHoursBetween(start2, end2);
        
        if(start2 < start1 && end1 < end2)
            return TimeUtil.getHoursBetween(start1, end1);

        return 0;
    }
}
